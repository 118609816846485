import React from "react"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import MarkdownField from "../components/MarkdownField"

const privacyPolicyMD = require("raw-loader!../markdown/privacy.md")

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />

      <MarkdownField path={privacyPolicyMD} />
    </Layout>
  )
}

export default Privacy
